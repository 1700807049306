import sendIcon from '../assets/bms_logo.svg.png'

const Body = () =>{
    return(
        <div style={{marginTop:"30px"}}>
            <center>
            <img style={{opacity:0.3, width:"350px", height:"350px"}} src={sendIcon }></img>

            </center>

        </div>
    )
}
export default Body;